.title{
    text-align: center;
    color: #212EA0;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 70px 0 30px;

}
.title h2{
    font-size: 30px;
    color: #000F38;
    margin-top: 5px;
    text-transform: uppercase;
}


@media only screen and (max-width: 1200px) {

    .title{
        text-align: center;
        color: #212EA0;
        font-size: 15px;
        font-weight: 800;
        text-transform: uppercase;
        margin: 70px 0 10px;
    
    }
    .title h2{
        font-size: 20px;
        color: #000F38;
        margin-top: 5px;
        font-weight: 400;
        text-transform: uppercase;
    }

  }
