nav{
    width: 100%;
    background: rgba(50, 5, 252, 0.5);
    color: #fff;
    padding: 4px 0;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
}
.logo{
    width: 300px;
}
nav ul li{
    display: inline-block;
    list-style: none;
    margin: 5px 12px;
    font-size: 14px;
}
.dark-nav{
    background: #212EA0;
    transition: 0.5s;
}
.menu-icon{
    display: none;
}

.double-navbar{
    flex-direction: column;
}
.btn.active {
    /* Example styles for the active link */
    font-weight: bold;
    color: #007bff;
  }

/* ============== Media Querry ============ */
@media (max-width: 1200px){
    .logo{
        width: 150px;
    }
    .nav ul li{
        margin: 10px 15px;
    }
}
@media (max-width: 960px){
    nav{
        padding: 15px 0;
    }
    nav ul{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        
        background: #212EA0;
        z-index: -1;
        width: 200px;
        padding-top: 70px;
        transition: 0.5s;
    }
    nav ul li{
        display: block;
        margin: 20px 30px;
    }
    .menu-icon{
        display: block;
        width: 35px;
        cursor: pointer;
        padding-right: 10px;
    }
    .hide-mobile-menu{
        right: -200px;
    }
}